<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>見積入力</v-card-title>
            <v-card-subtitle
              >編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</v-card-subtitle
            >
            <v-card-text>
              <v-form ref="formHeader">
                <v-row>
                  <!-- 見積番号 -->
                  <!-- ●TODO 画面表示時に見積番号が渡されていた場合は検索して項目を埋めていく処理 -->
                  <v-col cols="12" sm="2">
                    <v-text-field
                      v-model="headerData.mitsumoriNo"
                      label="見積番号"
                      :hint="`保存時に自動採番`"
                      persistent-hint
                      disabled
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 部門名 -->
                  <v-col cols="12" sm="2">
                    <v-text-field
                      v-model="headerData.bumonName"
                      label="部門名"
                      readonly
                      :hint="`初期設定値`"
                      persistent-hint
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 作成日付 -->
                  <v-col cols="12" sm="2">
                    <v-text-field
                      v-model="headerData.sakuseiDate"
                      label="作成日付"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <!-- 見積日付 -->
                  <v-col cols="12" sm="2">
                    <v-menu
                      v-model="createMitsumoriDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="headerData.mitsumoriDate"
                          label="見積日付"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          @click:append="createMitsumoriDateMenu = !createMitsumoriDateMenu"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="headerData.mitsumoriDate"
                        @input="createMitsumoriDateMenu = false"
                        :day-format="(date) => new Date(date).getDate()"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- 担当者コード -->
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      v-model="headerData.tantoshaCd"
                      label="担当者コード"
                      :items="tantoshaList"
                      item-text="comboItem"
                      item-value="code"
                      :rules="[required]"
                      clearable
                      @change="updateTantoshaCombo"
                    ></v-autocomplete>
                  </v-col>
                  <!-- 得意先カナ -->
                  <!-- ●得意先カナ選択時に自動入力できる項目の処理 -->
                  <v-col cols="12" sm="4">
                    <v-combobox
                      v-model="headerData.tokuisakiData"
                      label="得意先カナ"
                      :items="tokuisakiList"
                      item-text="comboItem"
                      item-value="code"
                      :rules="[required]"
                      clearable
                      @change="updateTokuisakiCombo"
                      autofocus
                      tabindex="1"
                    >
                    </v-combobox>
                  </v-col>
                  <!-- 得意先名 -->
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="headerData.tokuisakiName"
                      label="得意先名"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 有効期限 -->
                  <v-col cols="12" sm="2">
                    <v-text-field
                      v-model="headerData.yukoKigenSu"
                      label="有効期限"
                      :rules="[amountRequired, limit_length2, number]"
                      counter="2"
                      clearable
                      tabindex="2"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 納期 -->
                  <!-- ●TODO 自動採番された見積番号を反映か -->
                  <v-col cols="12" sm="2">
                    <v-text-field
                      v-model="headerData.nokiSu"
                      label="納期"
                      :rules="[amountRequired, limit_length2, number]"
                      counter="2"
                      clearable
                      tabindex="3"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 件名 -->
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="headerData.kenmeiName"
                      label="件名"
                      :rules="[limit_length60]"
                      counter="60"
                      clearable
                      tabindex="4"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 摘要 -->
                  <v-col cols="12" sm="12">
                    <v-textarea
                      v-model="headerData.tekiyo"
                      label="摘要"
                      counter="150"
                      rows="1"
                      :rules="[limit_length100]"
                      clearable
                      auto-grow
                      tabindex="5"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <!-- 見積明細データエリア -->
            <v-form ref="formDetails">
              <v-data-table
                dense
                :headers="headers"
                :items="mitsumoriMeisaiList"
                :items-per-page="5"
                class="elevation-5 mt-5 scroll-lock-estimate-input"
                item-key="meisaiNo"
                v-model="selected"
                show-select
                disable-sort
              >
                <template v-slot:top>
                  <v-form ref="formModal">
                    <v-toolbar flat>
                      <v-toolbar-title>見積明細</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>

                      <!-- 追加編集ダイアログ -->
                      <v-btn
                        class="mb-2 mx-2"
                        dark
                        color="secondary"
                        rounded
                        @click="addLine(5)"
                        tabindex="6"
                      >
                        <v-icon left>mdi-pen-plus</v-icon>行追加
                      </v-btn>
                      <v-dialog v-model="dialog" max-width="800px">
                        <v-card>
                          <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                            <v-divider class="mx-4 mb-1 mt-1" vertical></v-divider>
                            <span class="text-h5">明細No.{{ editmeisaiNo }}</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field
                                    v-model="editedItem.shiiresakiCd"
                                    label="仕入先コード"
                                    disabled
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-autocomplete
                                    v-model="editedItem.shiiresakiKanaName"
                                    tabindex="20"
                                    label="仕入先名カナ"
                                    id="shiiresakiKanaName"
                                    :items="shiiresakiList"
                                    item-text="comboItem"
                                    item-value="kana"
                                    :rules="[required]"
                                    autofocus
                                    @change="setShiiresakiData(editedItem)"
                                    clearable
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field
                                    v-model="editedItem.shiiresakiName"
                                    readonly
                                    label="仕入先名"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-combobox
                                    v-model="productObj"
                                    tabindex="21"
                                    label="型番検索"
                                    id="productNoSearching"
                                    :items="shohinList"
                                    :loading="onSearchingShohin"
                                    :search-input.sync="searchShohinName"
                                    item-text="comboItem"
                                    autofocus
                                    placeholder="3~10文字で入力"
                                    append-outer-icon="mdi-database-search"
                                    @change="setShohinData(editedItem, productObj)"
                                    @click:append-outer="
                                      (dialogShohinSearch = !dialogShohinSearch),
                                      (targetBranchNo = editedItem.meisaiNo)"
                                    clearable
                                  ></v-combobox>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field
                                    v-model="editedItem.katabanNo"
                                    :rules="[required, limit_length20, productNo]"
                                    tabindex="22"
                                    label="型番"
                                    id="productNo"
                                    counter="20"
                                    clearable
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field
                                    v-model="editedItem.shohinCd"
                                    readonly
                                    :rules="[requiredProduct]"
                                    label="商品コード"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field
                                    v-model="editedItem.shohinName"
                                    tabindex="23"
                                    label="品名"
                                    clearable
                                    counter="40"
                                    :counter-value="productCount"
                                    :rules="[required, limit_lengthProductName]"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field
                                    v-model.number="editedItem.suryoSu"
                                    tabindex="24"
                                    :rules="[
                                      amountRequired,
                                      limit_length9,
                                      number,
                                    ]"
                                    counter="9"
                                    :counter-value="numCount"
                                    clearable
                                    label="数量"
                                    type="number"
                                    hide-spin-buttons
                                    @change="setPrices(editedItem)"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field
                                    v-model.number="editedItem.tankaGk"
                                    tabindex="25"
                                    label="単価"
                                    type="number"
                                    hide-spin-buttons
                                    :rules="[amountRequired, price]"
                                    clearable
                                    counter="11"
                                    :counter-value="numCount"
                                    ref="moneyInput"
                                    @change="setPrices(editedItem)"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field
                                    v-model="editedItem.kingakuGk"
                                    tabindex="26"
                                    label="金額"
                                    readonly
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field
                                    v-model.number="editedItem.toshinNetGk"
                                    tabindex="27"
                                    label="トシンネット"
                                    type="number"
                                    hide-spin-buttons
                                    :rules="[amountRequired, price]"
                                    counter="11"
                                    :counter-value="numCount"
                                    clearable
                                    ref="moneyInput"
                                    @change="setPrices(editedItem, true)"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field
                                    v-model.number="editedItem.genkaGk"
                                    tabindex="28"
                                    :rules="[amountRequired, price]"
                                    counter="11"
                                    :counter-value="numCount"
                                    clearable
                                    label="原価"
                                    type="number"
                                    hide-spin-buttons
                                    @change="setPrices(editedItem)"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field
                                    v-model="editedItem.genkaKingakuGk"
                                    tabindex="29"
                                    label="原価金額"
                                    readonly
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field
                                    v-model.number="editedItem.tekaGk"
                                    tabindex="30"
                                    :rules="[limit_length8, number]"
                                    counter="8"
                                    :counter-value="numCount"
                                    clearable
                                    label="定価"
                                    type="number"
                                    hide-spin-buttons
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                  <v-text-field
                                    v-model="editedItem.biko"
                                    tabindex="31"
                                    label="備考"
                                    clearable
                                    :rules="[limit_length30]"
                                    counter="30"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                  <v-text-field
                                    v-model="editedItem.comment"
                                    tabindex="32"
                                    label="コメント"
                                    clearable
                                    :rules="[limit_length100]"
                                    counter="100"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn tabindex="33" dark color="secondary" rounded @click="close">
                              キャンセル
                            </v-btn>
                            <v-btn tabindex="34" dark color="secondary" rounded @click="saveClose"> OK </v-btn>
                            <v-btn tabindex="35" dark color="secondary" rounded @click="saveAddLine"> OK（続けて入力）</v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <!-- 商品検索ダイアログ -->
                      <v-dialog
                        v-model="dialogShohinSearch"
                        fullscreen
                        hide-overlay
                        transition="dialog-bottom-transition"
                        style="z-index: 1000;"
                      >
                        <v-card>
                          <v-toolbar dark color="primary">
                            <v-btn icon dark @click="dialogShohinSearch = false">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-toolbar>
                          <v-row>
                            <v-col>
                              <!-- 商品検索エリア（モーダル共通） -->
                              <product-search-area
                                :transitionFlg="true"
                                :targetBranchNo="targetBranchNo"
                                :dialogShohinSearch="true"
                                @result="response"
                                style="z-index: 2000;"
                              ></product-search-area>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-dialog>
                      <!-- 削除ダイアログ -->
                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                          <v-card-title class="text-h5">
                            削除します。よろしいですか?
                          </v-card-title>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn dark color="secondary" rounded @click="closeDelete">
                              キャンセル
                            </v-btn>
                            <v-btn
                              dark
                              color="secondary"
                              rounded
                              @click="deleteItemConfirm"
                            >
                              OK
                            </v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </v-form>
                </template>
                <!-- 仕入先コード -->
                <!-- 仕入先名カナ -->
                <template v-slot:[`item.shiiresakiKanaName`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.shiiresakiKanaName"
                    large
                    cancel-text="キャンセル"
                    save-text="OK"
                    @save="setShiiresakiData(item)"
                    @cancel="cancel"
                  >
                    <v-chip link>{{ item.shiiresakiKanaName }}</v-chip>
                    <template v-slot:input>
                      <div class="mt-4 text-h6">仕入先名カナ</div>
                      <v-autocomplete
                        v-model="item.shiiresakiKanaName"
                        id="shiiresakiKanaName"
                        :items="shiiresakiList"
                        item-text="comboItem"
                        item-value="kana"
                        :rules="[required]"
                        autofocus
                        clearable
                      ></v-autocomplete>
                    </template>
                  </v-edit-dialog>
                </template>
                <!-- 仕入先名 -->
                <!-- 型番検索 -->
                <template v-slot:[`item.katabanSearch`]="{ item }">
                  <v-edit-dialog
                    :id="'kataban_dialog_' + item.meisaiNo"
                    large
                    cancel-text="キャンセル"
                    save-text="OK"
                    @open="openProductNo(item)"
                    @save="setShohinData(item, productObj)"
                    @cancel="cancel"
                  >
                    <v-icon>mdi-database-search</v-icon>
                    <template v-slot:input>
                      <div class="mt-4 text-h6">型番</div>
                      <v-combobox
                          v-model="productObj"
                          id="productNo"
                          :items="shohinList"
                          :loading="onSearchingShohin"
                          :search-input.sync="searchShohinName"
                          item-text="comboItem"
                          autofocus
                          placeholder="3~10文字で入力"
                          append-outer-icon="mdi-database-search"
                          @click:append-outer="
                            (dialogShohinSearch = !dialogShohinSearch),
                              (targetBranchNo = item.meisaiNo)
                          "
                        ></v-combobox>
                    </template>
                  </v-edit-dialog>
                </template>
                <!-- 型番 -->
                <template v-slot:[`item.katabanNo`]="{ item }">
                  <v-edit-dialog
                    :id="'kataban_dialog_' + item.meisaiNo"
                    :return-value.sync="item.katabanNo"
                    large
                    cancel-text="キャンセル"
                    save-text="OK"
                    @cancel="cancel"
                  >
                    <v-chip link>{{ item.katabanNo }}</v-chip>
                    <template v-slot:input>
                      <div class="mt-4 text-h6">型番</div>
                      <v-text-field
                        v-model="item.katabanNo"
                        id="katabanNo"
                        :rules="[required, limit_length20, productNo]"
                        single-line
                        counter="20"
                        autofocus
                        clearable
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <!-- 商品コード -->
                <!-- 品名 -->
                <template v-slot:[`item.shohinName`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.shohinName"
                    large
                    cancel-text="キャンセル"
                    save-text="OK"
                    @cancel="cancel"
                  >
                    <v-chip link>{{ item.shohinName }}</v-chip>
                    <template v-slot:input>
                      <div class="mt-4 text-h6">品名</div>
                      <v-text-field
                        v-model="item.shohinName"
                        id="shohinName"
                        :rules="[required, limit_lengthProductName]"
                        single-line
                        counter="40"
                        :counter-value="productCount"
                        clearable
                        autofocus
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <!-- 数量 -->
                <template v-slot:[`item.suryoSu`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.suryoSu"
                    large
                    cancel-text="キャンセル"
                    save-text="OK"
                    @save="setPrices(item)"
                    @cancel="cancel"
                  >
                    <v-chip link>{{ item.suryoSu }}</v-chip>
                    <template v-slot:input>
                      <div class="mt-4 text-h6">数量</div>
                      <v-text-field
                        v-model.number="item.suryoSu"
                        id="suryoSu"
                        :rules="[amountRequired, limit_length9, number]"
                        single-line
                        counter="9"
                        :counter-value="numCount"
                        clearable
                        autofocus
                        type="number"
                        hide-spin-buttons
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <!-- 単価 -->
                <template v-slot:[`item.tankaGk`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.tankaGk"
                    large
                    cancel-text="キャンセル"
                    save-text="OK"
                    @save="setPrices(item)"
                    @cancel="cancel"
                  >
                    <v-chip link>{{ item.tankaGk == null || item.tankaGk == "" ? item.tankaGk : new Number(item.tankaGk).toLocaleString() }}</v-chip>
                    <template v-slot:input>
                      <div class="mt-4 text-h6">単価</div>
                      <v-text-field
                        v-model.number="item.tankaGk"
                        type="number"
                        hide-spin-buttons
                        :rules="[amountRequired, price]"
                        single-line
                        counter="11"
                        :counter-value="numCount"
                        autofocus
                        clearable
                        ref="moneyInput"
                        id="tankaGk"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <!-- 金額 -->
                <template v-slot:[`item.kingakuGk`]="{ item }">
                  {{ item.kingakuGk == null || item.kingakuGk == "" ? item.kingakuGk : new Number(item.kingakuGk).toLocaleString() }}
                </template>
                <!-- トシンネット -->
                <template v-slot:[`item.toshinNetGk`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.toshinNetGk"
                    large
                    cancel-text="キャンセル"
                    save-text="OK"
                    @save="setPrices(item, true)"
                    @cancel="cancel"
                  >
                    <v-chip link>{{ item.toshinNetGk == null || item.toshinNetGk == "" ? item.toshinNetGk : new Number(item.toshinNetGk).toLocaleString() }}</v-chip>
                    <template v-slot:input>
                      <div class="mt-4 text-h6">トシンネット</div>
                      <v-text-field
                        v-model.number="item.toshinNetGk"
                        type="number"
                        hide-spin-buttons
                        :rules="[amountRequired, price]"
                        single-line
                        counter="11"
                        :counter-value="numCount"
                        autofocus
                        clearable
                        ref="moneyInput"
                        @change="setPrices(editedItem)"
                        id="toshinNetGk"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <!-- 原価 -->
                <template v-slot:[`item.genkaGk`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.genkaGk"
                    large
                    cancel-text="キャンセル"
                    save-text="OK"
                    @save="setPrices(item)"
                    @cancel="cancel"
                  >
                    <v-chip link>{{ item.genkaGk == null || item.genkaGk == "" ? item.genkaGk : new Number(item.genkaGk).toLocaleString() }}</v-chip>
                    <template v-slot:input>
                      <div class="mt-4 text-h6">原価</div>
                      <v-text-field
                        v-model.number="item.genkaGk"
                        :rules="[amountRequired, price]"
                        type="number"
                        hide-spin-buttons
                        counter="11"
                        :counter-value="numCount"
                        clearable
                        id="genkaGk"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <!-- 原価金額 -->
                <template v-slot:[`item.genkaKingakuGk`]="{ item }">
                  {{ item.genkaKingakuGk == null || item.genkaKingakuGk == "" ? item.genkaKingakuGk : new Number(item.genkaKingakuGk).toLocaleString() }}
                </template>
                <!-- 定価 -->
                <template v-slot:[`item.tekaGk`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.tekaGk"
                    large
                    cancel-text="キャンセル"
                    save-text="OK"
                    @cancel="cancel"
                  >
                    <v-chip link>{{ item.tekaGk == null || item.tekaGk == "" ? item.tekaGk : new Number(item.tekaGk).toLocaleString() }}</v-chip>
                    <template v-slot:input>
                      <div class="mt-4 text-h6">定価</div>
                      <v-text-field
                        v-model.number="item.tekaGk"
                        type="number"
                        hide-spin-buttons
                        :rules="[limit_length8, number]"
                        counter="8"
                        :counter-value="numCount"
                        clearable
                        id="tekaGk"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <!-- 備考 -->
                <template v-slot:[`item.biko`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.biko"
                    large
                    cancel-text="キャンセル"
                    save-text="OK"
                    @cancel="cancel"
                  >
                    <v-chip link>{{ item.biko }}</v-chip>
                    <template v-slot:input>
                      <div class="mt-4 text-h6">備考</div>
                      <v-text-field
                        v-model="item.biko"
                        :rules="[limit_length30]"
                        single-line
                        counter="30"
                        autofocus
                        clearable
                        id="biko"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <!-- コメント -->
                <template v-slot:[`item.comment`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.comment"
                    large
                    cancel-text="キャンセル"
                    save-text="OK"
                    @cancel="cancel"
                  >
                    <v-chip link>{{ item.comment }}</v-chip>
                    <template v-slot:input>
                      <div class="mt-4 text-h6">コメント</div>
                      <v-text-field
                        v-model="item.comment"
                        :rules="[limit_length100]"
                        single-line
                        counter="100"
                        autofocus
                        clearable
                        id="comment"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <!-- 操作 -->
                <template v-slot:[`item.actions`]="{ item }">
                  <v-container>
                    <v-row>
                      <v-tooltip bottom
                        ><template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="m-0"
                            fab
                            dark
                            x-small
                            color="secondary"
                            v-bind="attrs"
                            v-on="on"
                            @click="editItem(item)"
                            tabindex="7"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn></template
                        >
                        <span>修正</span>
                      </v-tooltip>
                      <v-tooltip bottom
                        ><template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="ml-1"
                            fab
                            dark
                            x-small
                            color="secondary"
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteItem(item)"
                            tabindex="8"
                            ><v-icon>mdi-delete</v-icon>
                          </v-btn></template
                        >
                        <span>削除</span>
                      </v-tooltip>
                    </v-row>
                  </v-container>
                </template>
                <!-- データなし -->
                <template v-slot:no-data>
                  <v-btn dark color="secondary" rounded @click="initialize">
                    リセット
                  </v-btn>
                </template>
              </v-data-table>
              <div class="text-center">
                <v-chip pill class="ma-2" color="green" text-color="white">
                  <v-avatar left class="green darken-4" size="32"> 合計 </v-avatar>
                  {{ new Number(totalGokeiKingakuGk).toLocaleString() }}
                </v-chip>
                <v-chip pill class="ma-2" color="green" text-color="white">
                  <v-avatar left class="green darken-4" size="32"> 原計 </v-avatar>
                  {{ new Number(totalGenkaKingakuGk).toLocaleString() }}
                </v-chip>
                <v-chip pill class="ma-2" color="green" text-color="white">
                  <v-avatar left class="green darken-4" size="32"> 利益 </v-avatar>
                  {{ new Number(profit).toLocaleString() }}
                </v-chip>
                <v-chip pill class="ma-2" color="green" text-color="white">
                  <v-avatar left class="green darken-4" size="32"> 利率 </v-avatar>
                  {{ profitRatio }}
                </v-chip>
              </div>
            </v-form>
            <v-divider></v-divider>
            <v-card-actions class="justify-center">
              <!-- 保存ボタン -->
              <v-btn
                color="secondary"
                v-on:click="onClickRegister"
                rounded
                class="mb-2 mx-2"
                tabindex="9"
              >
                <v-icon left> mdi-content-save </v-icon>保存
              </v-btn>
              <!-- <span v-if="success">バリデーションOK！</span> -->
              <!-- 見積書PDF出力ボタン -->
              <!-- ●TODO 未保存であれば警告かエラー -->
              <v-btn
                color="secondary"
                v-on:click="printing"
                rounded
                class="mb-2 mx-2"
                tabindex="10"
              >
                <v-icon left> mdi-file-pdf-box </v-icon>見積書PDF出力
              </v-btn>
              <!-- コピーして新規入力ボタン -->
              <!-- ●TODO 保存していない場合はエラー -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mb-2 mx-2"
                    color="secondary"
                    v-bind="attrs"
                    v-on="on"
                    @click="copy"
                    rounded
                    tabindex="11"
                  >
                    <v-icon left>mdi-content-copy</v-icon>コピーして新規入力
                  </v-btn>
                </template>
                <span
                  >表示している見積の内容で新しく見積入力画面を表示します</span
                >
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import ProductSearchArea from "../components/ProductSearchArea.vue";
import { API, graphqlOperation } from 'aws-amplify'
import { getT_CHOHYO_COUNTER, listM_SHIIRESAKI, listM_TANTOSHA, getM_SHOKISETTEI, listV_MITSUMORI_DATA_WITH_MEISAI } from '@/graphql/queries';
import { executeTransactSql } from '@/graphql/mutations';
import { CONFIRM_MESSAGE_UNSAVED_DATA, CONFIRM_MESSAGE_SAVE_DATA, CONFIRM_MESSAGE_CLEAR_DATA, ALERT_MESSAGE_COMMON_ERROR, ALERT_SAVE_ZERO_DATA, ALERT_COMPLETE_CRUD_DATA, ALERT_ALREADY_SAVED_DATA, ALERT_COPY_COMPLETE, ALERT_UNSAVED_DATA } from "@/assets/js/dialog_messages";
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
import { executeHanbaikanriSqls } from "@/assets/js/common";
// import HanbaikanriSampleData from "@/assets/js/hanbaikanri";
export default {
  name: "EstimateInput",

  components: {
    ProductSearchArea,
  },
  props: {
    // 修正用 - 発注データID
    reqMitsumoriDataId: Number,
    // 商品検索モーダル返却値 - 商品コード
    returnProductCode: Number,
    // 遷移フラグ
    transitionFlg: Boolean,
  },
  data: () => ({
    // 画面上の設定値（見積ヘッダ）
    headerData: {
      // 見積データID
      mitsumoriDataId: null,
      // 部門名
      // bumonName: null,
      // 部門コード
      bumonCd: null,
      // 見積番号
      mitsumoriNo: null,
      // 見積日付
      mitsumoriDate: null,
      // 作成日付
      sakuseiDate: null,
      // 件名
      kenmeiName: null,
      // 有効期限
      yukoKigenSu: 30,
      // 納期
      nokiSu: 30,
      // 合計金額
      gokeiKingakuGk: null,
      // 得意先カナ
      tokuisakiData: null,
      // 得意先コード
      tokuisakiCd: null,
      // 得意先名
      tokuisakiName: null,
      // 摘要
      tekiyo: null,
      // ログインユーザーコード
      loginUserCd: null,
      // 担当者コード
      tantoshaCd: null,
      // 担当者名
      tantoshaName: null,
    },

    // 未保存チェック用
    originalHeader: {},
    originalDetails: [],

    // ログインユーザー情報
    loginUserInfo: null,
    // ログインユーザー所属部門情報
    departmentInfo: null,

    // 見積日付表示用フラグ
    createMitsumoriDateMenu: false,

    // 仕入先リスト（コード、名前）
    shiiresakiList: [],
    // 部門名
    bumonName: "",
    // 部門コード
    bumonCd: 0,
    // 見積番号
    mitsumoriNo: "",
    // 見積日付
    mitsumoriDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu1: false,
    // 担当者コード
    tantosha: { text: "", value: 0 },
    tantoshaList: [],

    // 得意先データ
    tokuisakiData: {},
    tokuisakiList: [],
    // 得意先コード
    tokuisakiCd: 0,
    // 得意先名
    tokuisakiName: "",

    // 販売管理：SHOHINテーブルデータ
    shohinData: {},
    shohinList: [],

    // 送信が成功したかどうかのフラグ
    success: false,

    // Datatablesのデータ
    estimates: [],
    // データテーブルローディングフラグ
    tableLoading: false,

    // 追加編集ダイアログ
    dialog: false,
    // 削除ダイアログ
    dialogDelete: false,
    // 商品検索ダイアログ
    dialogShohinSearch: false,

    // Datatablesのヘッダー
    headers: [
      { text: "", value: "data-table-select", width: "50px", align:"center" },
      { text: "操作", value: "actions", width: "80px" },
      { text: "明細No", value: "meisaiNo", width: "40px",},
      { text: "仕入先コード", value: "shiiresakiCd", width: "50px" },
      { text: "仕入先名カナ", value: "shiiresakiKanaName", width: "150px" },
      { text: "仕入先名", value: "shiiresakiName", width: "200px" },
      { text: "検索", value: "katabanSearch", width: "40px" },
      { text: "型番", value: "katabanNo", width: "100px" },
      { text: "商品コード", value: "shohinCd", width: "80px" },
      { text: "品名", value: "shohinName", width: "200px" },
      { text: "数量", value: "suryoSu", width: "50px" },
      { text: "単価", value: "tankaGk", width: "50px" },
      { text: "金額", value: "kingakuGk", width: "50px" },
      { text: "トシンネット", value: "toshinNetGk", width: "50px" },
      { text: "原価", value: "genkaGk", width: "30px" },
      { text: "原価金額", value: "genkaKingakuGk", width: "40px" },
      { text: "定価", value: "tekaGk", width: "30px" },
      { text: "備考", value: "biko", width: "200px" },
      { text: "コメント", value: "comment", width: "200px" },
    ],
    // テーブルに格納するデータの初期値
    mitsumoriMeisaiList: [],
    // CSS得意先コードリスト
    cssClientNameList: [],
    // チェックボックス
    selected: [],
    // 修正画面表示用フラグ
    reqUpdate: false,
    // Datatablesのデータ
    items: [],
    // 編集インデックス
    editedIndex: -1,
    // 追加編集データ
    editedItem: {},
    // 明細データの初期値
    defaultItem: {
      // 見積データID
      mitsumoriDataId: null,
      // 明細No
      meisaiNo: null,
      // 仕入先コード
      shiiresakiCd: null,
      // 仕入先名称カナ
      shiiresakiKanaName: null,
      // 仕入先名称
      shiiresakiName: null,
      // 商品コード
      shohinCd: null,
      // 型番
      katabanNo: null,
      // 品名
      shohinName: null,
      // 原価（SH_SIRTNA）
      shohinGenka: 0,
      // 原価
      genkaGk: 0,
      // 原価金額
      genkaKingakuGk: 0,
      // 単価
      tankaGk: 0,
      // 定価
      tekaGk: 0,
      // 数量
      suryoSu: 0,
      // 金額
      kingakuGk: 0,
      // トシンネット
      toshinNetGk: 0,
      // 備考
      biko: null,
      // コメント
      comment: null,
    },

    // 合計（合計金額）
    totalGokeiKingakuGk: 0,
    // 原計（原価合計）
    totalGenkaKingakuGk: 0,
    // 利益
    profit: 0,
    // 利益率
    profitRatio: 0,

    targetBranchNo: null,

    // API検索用オートコンプリート
    onSearchingShohin: false,
    searchShohinName: null,
    productObj: null,
    // 編集モーダル用明細No
    editmeisaiNo: null,
    // 入力規則
    // 入力必須の制約
    required: (value) => !!value || "必ず入力してください",
    requiredProduct: (value) => !!value || "型番検索してください",
    amountRequired: (value) =>
      (!!`${value}` && value !== null) || "必ず入力してください",
    // 文字数の制約 最大2文字
    limit_length2: (value) =>
      (!value || value.toString().length <= 2) || "2文字以内で入力してください",
    // 文字数の制約 最大7文字
    limit_length7: (value) =>
      (!value || value.toString().length <= 7) || "7文字以内で入力してください",
    // 文字数の制約 最大8文字
    limit_length8: (value) =>
      (!value || value.toString().length <= 8) || "8文字以内で入力してください",
    // 文字数の制約 最大9文字
    limit_length9: (value) =>
      (!value || value.toString().length <= 9) || "9桁以内で入力してください",
    // 文字数の制約 最大11文字
    limit_length11: (value) =>
      (!value || value.toString().length <= 11) || "11桁以内で入力してください",
    // 文字数の制約 最大13文字
    limit_length13: (value) =>
      (!value || value.length <= 13) || "13文字以内で入力してください",
    // 文字数の制約 最大20文字
    limit_length20: (value) =>
      (!value || value.length <= 20) || "20文字以内で入力してください",
    // 文字数の制約 最大30文字
    limit_length30: (value) =>
      (!value || value.length <= 30) || "30文字以内で入力してください",
    // 文字数の制約 最大40文字
    limit_length40: (value) =>
      (!value || value.length <= 40) || "40文字以内で入力してください",
    // 文字数の制約 最大60文字
    limit_length60: (value) =>
      (!value || value.length <= 60) || "60文字以内で入力してください",
    // 文字数の制約 最大80文字
    limit_length80: (value) =>
      (!value || value.length <= 80) || "80文字以内で入力してください",
    // 文字数の制約 最大150文字
    limit_length100: (value) =>
      (!value || value.length <= 100) || "100文字以内で入力してください",
    // 文字数の制約 半角40文字、全角20文字
    limit_lengthProductName: value => {
      let len = 0;
      if (value !== null) {
        // 半角1文字、全角2文字としてカウント
        for (let i = 0; i < value.length; i++) {
          (value[i].match(/[ -~]/)) || (value[i].match(/[ｦ-ﾟ]/)) ? (len += 1) : (len += 2);
        }
      }
      return len <= 40 || `半角40文字、全角20文字以内で入力してください`
    },
    // 型番の制約
    productNo: (value) => value == null || (/^[ -~ｦ-ﾟ]*$/).test(value) || "半角で入力してください",
    // 数値の制約
    number: (value) =>
      value == null || /^[0-9,.-]+$/.test(value) || "数値で入力してください",
    // 価格の制約
    price: (value) =>
      value == null ||
      /^([-+]?[1-9][0-9]{0,7}|0)(\.[0-9]{1,2})?$/.test(
        String(value).replace(/,/g, "").trim()
      ) ||
      "整数値8桁、小数値2桁で入力してください",
    // 数値系のカウント
    numCount: (v) => {
      if (v !== null) {
        return `${v}`.replace(/-/g, "").trim().length;
      } else {
        return 0;
      }
    },
    // 商品項目（sjis）のカウント
    productCount: (v) => {
      if (v !== null) {
        let len = 0;
        // 半角1文字、全角2文字としてカウント
        for (let i = 0; i < v.length; i++) {
          (v[i].match(/[ -~]/)) || (v[i].match(/[ｦ-ﾟ]/)) ? (len += 1) : (len += 2);
        }
        return len;
      } else {
        return 0;
      }
    },
  }),
  computed: {
    // ダイアログタイトル設定
    formTitle() {
      return this.editedIndex === -1 ? "追加" : "編集";
    },
  },
  watch: {
    // 追加編集ダイアログ監視
    dialog(val) {
      val || this.close();
    },
    // 削除ダイアログ監視
    dialogDelete(val) {
      val || this.closeDelete();
    },
    /**
     * 品番のコンボボックス作成
     */
    async searchShohinName(val) {
      // メソッド名を定義
      const method_name="searchShohinName"
      // console.log('searchShohin');
      /**
       * 入力値がNULL、3文字以内、オートコンプリートの選択値（7文字以上）だった場合
       * またはすでに商品を検索中の場合は検索しない
       */
      if (val == null || val.length < 3 || val.length > 7 || this.onSearchingShohin) {
        return;
      }

      // オートコンプリートをローディング中にする
      this.onSearchingShohin = true
      // 商品コードのリストを初期化
      this.shohinList = [];
      let sqlList = [
        `SELECT SH_SHOCD, SH_SHONM, SH_SHOKNM, SH_SIRTNA, SH_JYOTNA, SH_JAN FROM SHOHIN` +
        ` WHERE SH_SHOKNM LIKE '${val}%' `+
        ` AND ((SH_SBUBCD >= 40000 AND SH_SBUBCD < 60000) OR (SH_SBUBCD >= 10000 AND SH_SBUBCD < 20000))` +
        ` AND SH_SHOKNM IS NOT NULL AND SH_HAINOKB = 9` +
        ` ORDER BY SH_SHOKNM`
      ];
      
      let result = await executeHanbaikanriSqls(this.$route.name, method_name, sqlList);
      // テスト用
      // let result = {
      //   body: {
      //     data: [],
      //   }
      // };
      // result.body.data[0] = HanbaikanriSampleData.ShohinList4CF; // テスト用
      // console.log({result});
      if (!result.body.error) {
        // console.log('start roop')
        for (const data of result.body.data[0]) {
          this.shohinList.push({
            comboItem: data.SH_SHOCD + "：" + data.SH_SHOKNM,
            code: data.SH_SHOCD,
            name: data.SH_SHONM,
            productNo: data.SH_SHOKNM,
            tanka: data.SH_SIRTNA,
            teka: data.SH_JYOTNA,
            janCode: data.SH_JAN,
          });
        }
        // オートコンプリートのローディング中を解除する
        this.onSearchingShohin = false;
      } else {
        // エラーダイアログを表示
        alert("販売管理DBから商品データを取得できませんでした。\nしばらくしてから、もう一度お試しください。");
        // オートコンプリートのローディングを解除
        this.onSearchingShohin = false;
      }
    },
  },
  async created() {
    // ローディング画面の開始
    this.$store.commit("setLoading", true);

    // ログイン中のユーザー情報を取得
    if (!(await this.getLoginUserInfo())) {
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      return;
    }

    // 初期化処理の実行
    if (!(await this.initialize())) {
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      return;
    }

    // ローディングを解除
    this.$store.commit("setLoading", false);
  },
  mounted() {
    // バリデーションエラーをリセット
    this.$refs.formHeader.resetValidation();
    // ローディングOFF
    this.$store.commit("setLoading", false);
  },
  /**
   * beforeRouteLeave ライフサイクルフック
   */
  async beforeRouteLeave(to, from, next) {
    // 未保存チェックを実施し、未保存の場合はエラーを表示
    if (!this.noDataChanges()) {
      if (confirm(CONFIRM_MESSAGE_UNSAVED_DATA)) {
        // バリデーションチェックでエラーの際は登録しない
        if (!(await this.validateCheck())) {
          return;
        }
        // ローディング画面の開始
        this.$store.commit("setLoading", true);
        // 空行を削除と商品コードのnullチェックを行う
        if (!(await this.deleteTable())) {
          return;
        }
        if (this.mitsumoriMeisaiList.length === 0) {
          alert(ALERT_SAVE_ZERO_DATA);
          // 行を15行追加
          this.addLine(15);
          return;
        }
        // 修正時の判定
        if (
          this.reqMitsumoriDataId ||
          typeof this.headerData.mitsumoriDataId == "number"
        ) {
          // 修正：入力データで更新
          if (!(await this.updateTable())) {
            // ローディングを解除
            this.$store.commit("setLoading", false);
            // エラーダイアログを表示
            alert(ALERT_MESSAGE_COMMON_ERROR);
            // 画面遷移しない
            return;
          }
        } else {
          // 新規登録：入力データを登録
          if (!(await this.insertTable())) {
            // ローディングを解除
            this.$store.commit("setLoading", false);
            // エラーダイアログを表示
            alert(ALERT_MESSAGE_COMMON_ERROR);
            // 画面遷移しない
            return;
          }
        }
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // 完了ダイアログを表示
        alert(ALERT_COMPLETE_CRUD_DATA);
      }
    }
    next();
  },
  methods: {
    /**
     * 仕入先名カナ選択時の自動入力
     */
    setShiiresakiData(item) {
    // 仕入先カナの値に基づく仕入先コード、仕入先名を設定
    if (item.shiiresakiKanaName) {
      const selectedShiiresaki = this.shiiresakiList.find(
        (items) => items.kana === item.shiiresakiKanaName
      );
      // 編集モーダルが開かれている場合は、this.editedItemに値を代入
      if (this.dialog) {
        this.editedItem.shiiresakiKanaName = selectedShiiresaki.kana;
        this.editedItem.shiiresakiCd = selectedShiiresaki.code;
        this.editedItem.shiiresakiName = selectedShiiresaki.name;
      } else {
        // 明細行に値を代入
        item.shiiresakiKanaName = selectedShiiresaki.kana;
        item.shiiresakiCd = selectedShiiresaki.code;
        item.shiiresakiName = selectedShiiresaki.name;
      }
    } else {
      // 編集モーダルが開かれている場合は、this.editedItemの値をクリア
      if (this.dialog) {
        this.editedItem.shiiresakiKanaName = null;
        this.editedItem.shiiresakiCd = null;
        this.editedItem.shiiresakiName = null;
      } else {
        // 明細行の値をクリア
        item.shiiresakiKanaName = null;
        item.shiiresakiCd = null;
        item.shiiresakiName = null;
      }
    }
    },
    /**
     * 型番選択時の自動入力
     */
    setShohinData(item, productObj) {
      // console.log('setShohinData called');
      // console.log({item});
      // メソッド名を定義
      const method_name="setShohinData"
      // オペレーションタイプを定義
      const operation_type="0004"

      // 商品コード未選択の場合はreturn
      if (typeof productObj !== 'object' || !productObj) {
        return;
      }
      // ローディング画面の開始
      // this.$store.commit("setLoading", true);
      // const shohinList = this.shohinList;
      // console.log({shohinList});
      const selectedShohin = this.shohinList.find(
        (items) => items.code === productObj.code
      );
      // console.log({selectedShohin});  
      // 検索した商品がリストにいない場合、何もしない
      if (!selectedShohin) {
        return;
      }
      if (item.code !== "") {
        item.katabanNo = selectedShohin.productNo;
        item.shohinCd = selectedShohin.code;
        item.shohinName = selectedShohin.name;
        item.shohinGenka = selectedShohin.tanka;
        item.tekaGk = selectedShohin.teka;
        // item.arrivalPurchasePrice = selectedShiiresaki.arrivalPurchasePrice;
      } else {
        this.editedItem.katabanNo = selectedShohin.productNo;
        this.editedItem.shohinCd = selectedShohin.code;
        this.editedItem.shohinName = selectedShohin.name;
        // 原価はトシンネットの入力状況によって変わるため、直接セットせずshohinGenkaとして別に持っておく
        this.editedItem.shohinGenka = selectedShohin.tanka;
        this.editedItem.tekaGk = selectedShohin.teka;
        // this.editedItem.arrivalPurchasePrice = selectedShiiresaki.arrivalPurchasePrice;
      }
      // 価格類を更新
      this.setPrices(item);

      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 自動計算金額の反映
     */
    setPrices(item = null, toshinEdited = false) {
      // console.log('setPrices called');
      // console.log({item});

      // itemが渡されない場合は合計系の計算のみ行う
      if (item) {
        // 検索した商品がリストにいない場合、何もしない
        if (item.code !== "") {
          /**
           * 原価計算
           * 条件1.トシンネット入力なし→販売管理SHOHINテーブルの仕入れ単価
           * 条件2.トシンネット入力あり→トシンネット * 東北圏見積ソフトDB「初期設定」”トシン掛率” ※小数点第3位切り上げ
           */
          if (toshinEdited) {
            if (item.toshinNetGk > 0) {
              item.genkaGk = Math.ceil(item.toshinNetGk * this.departmentInfo.TOSHIN_KAKERITSU_SU * 100) / 100;
            } else {
              // トシンが未入力の場合商品の仕入れ単価を表示
              item.genkaGk = item.shohinGenka;
            }
          }

          let kingakuGk = item.suryoSu * item.tankaGk;
          let genkaKingakuGk = item.suryoSu * item.genkaGk;
          // 数値に変換できない場合、nullを代入する
          item.kingakuGk = isNaN(kingakuGk) ? null : Math.ceil(kingakuGk);
          item.genkaKingakuGk = isNaN(genkaKingakuGk) ? null : genkaKingakuGk;
        } else {
          if (toshinEdited) {
            if (this.editedItem.toshinNetGk > 0) {
              this.editedItem.genkaGk = Math.ceil(this.editedItem.toshinNetGk * this.departmentInfo.TOSHIN_KAKERITSU_SU * 100) / 100;
            } else {
              // トシンが未入力の場合商品の仕入れ単価を表示
              this.editedItem.genkaGk = this.editedItem.shohinGenka;
            }
          }

          // 金額・原価金額
          let kingakuGk = this.editedItem.suryoSu * this.editedItem.tankaGk;
          let genkaKingakuGk = this.editedItem.suryoSu * this.editedItem.genkaGk;
          // 数値に変換できない場合、nullを代入する
          this.editedItem.kingakuGk = isNaN(kingakuGk) ? null : Math.ceil(kingakuGk);
          this.editedItem.genkaKingakuGk = isNaN(genkaKingakuGk) ? null : genkaKingakuGk;
        }
      }
      
      // 合計金額計算
      this.totalGokeiKingakuGk = this.mitsumoriMeisaiList.reduce((a, b) => a + (b['kingakuGk'] || 0), 0);
      if(this.totalGokeiKingakuGk > 0) {
        this.totalGenkaKingakuGk = this.mitsumoriMeisaiList.reduce((a, b) => a + (b['genkaKingakuGk'] || 0), 0);
        this.profit = this.totalGokeiKingakuGk - this.totalGenkaKingakuGk;
        // 利率（小数点第三位で四捨五入）
        this.profitRatio = Math.round(this.profit / this.totalGokeiKingakuGk * 100 * 100) / 100;
      }
    },
    /**
     * ログインユーザー情報の取得処理
     * ・ログインユーザーコードの取得
     * ・ログインユーザーの所属部門の取得
     */
    async getLoginUserInfo() {
      // メソッド名を定義
      const method_name="getLoginUserInfo"
      // ログイン中のユーザー情報を取得
      this.loginUserInfo = this.$store.getters.user;
      // 部門名をセット
      this.bumonName = this.loginUserInfo.BUMON_NAME;

      // データ取得
      let condition = { BUMON_CD: this.loginUserInfo.BUMON_CD };

      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(getM_SHOKISETTEI, condition)
        );
        // 結果判定
        if (result.data.getM_SHOKISETTEI) {
          // データを取得できた場合
          // ログイン中ユーザーの所属部門情報を取得
          this.departmentInfo = result.data.getM_SHOKISETTEI;
        } else {
          if (result.errors) {
            // データを取得できなかった場合
            // ログ出力(SQL実行でエラー)
            addLog('ERROR', {message: MSG.ERROR["3010"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // データ0件時
            return false;
          }
        }
      } catch (error) {
        // Exception発生の場合
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      return true;
    },
    /**
     * 初期化処理
     * ・コンボボックスのデータ取得
     * ・テキストボックスのデータ取得
     */
    async initialize() {
      // // メソッド名を定義
      const method_name="initialize"
      // ローディング画面の表示
      this.$store.commit("setLoading", true);

      // 行追加用データを空の明細データで初期化
      this.editedItem = Object.assign({}, this.defaultItem);

      // 新規登録の場合
      // 部門名・発注番号用テキストボックスのデータ取得
      let condition = { BUMON_CD: this.loginUserInfo.BUMON_CD };
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(getM_SHOKISETTEI, condition)
        );
        // 結果判定
        if (result.data.getM_SHOKISETTEI) {
          // データを取得できた場合
          // 取得結果をリストに追加
          let resultData = result.data.getM_SHOKISETTEI;
          this.headerData.bumonCd = resultData.BUMON_CD;
          this.headerData.bumonName = resultData.BUMON_NAME;
        } else {
          if (result.errors) {
            // データを取得できなかった場合
            // ログ出力(SQL実行でエラー)
            addLog('ERROR', {message: MSG.ERROR["3010"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // データ0件時
            return false;
          }
        }
      } catch (error) {
        // Exception発生の場合
        console.log(JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }

      // 得意先カナ用コンボボックスのリスト生成SQL
      let tokuisakiSql = '';
      tokuisakiSql += `SELECT TK_KANA, TK_TORCD, TK_TORNM1, TK_TANCD FROM TOKUI`;
      tokuisakiSql += ` WHERE TK_BASCD = ${this.loginUserInfo.BUMON_CD}`;
      tokuisakiSql += ` AND TK_EDELKB <> 1`;
      tokuisakiSql += ` AND SUBSTR(TK_TORCD, -2) <> 99`;
      tokuisakiSql += ` ORDER BY TK_KANA`;

      let sqlList = [tokuisakiSql];
      // console.log({sqlList});
      let hanbaikanriResult = await executeHanbaikanriSqls(this.$route.name, method_name, sqlList);
      // console.log({hanbaikanriResult});

      // テスト用
      // let hanbaikanriResult = {
      //   body: {
      //     data: [],
      //   }
      // };
      // hanbaikanriResult.body.data[0] = HanbaikanriSampleData.TokuisakiList; // テスト用

      if (!hanbaikanriResult.body.error) {
        // 得意先リスト
        let tempTokuisakiList = [];
        for (const tokuisaki of hanbaikanriResult.body.data[0]) {
          tempTokuisakiList.push({
            comboItem: tokuisaki.TK_TORCD + "：" + tokuisaki.TK_KANA,
            kana: tokuisaki.TK_KANA,
            name: tokuisaki.TK_TORNM1,
            code: tokuisaki.TK_TORCD,
            managerCode: tokuisaki.TK_TANCD,
          });
        }
        this.tokuisakiList = tempTokuisakiList;
      } else {
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // オートコンプリートのローディングを解除
        this.isLoadingClient = false;
        return;
      }

      // 仕入先カナ用コンボボックスのリスト生成
      condition = {
        where_options:
          "AND BUMON_CD = " +
          this.loginUserInfo.BUMON_CD +
          " ORDER BY SHIIRESAKI_CD",
      };
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listM_SHIIRESAKI, condition)
        );
        // 結果判定
        if (result.data.listM_SHIIRESAKI) {
          // データを取得できた場合
          // 取得結果をリストに追加
          let resultData = result.data.listM_SHIIRESAKI;
          for (const data of resultData) {
            this.shiiresakiList.push({
              comboItem: data.SHIIRESAKI_CD + "：" + data.SHIIRESAKI_KANA_NAME,
              code: data.SHIIRESAKI_CD,
              name: data.SHIIRESAKI_NAME,
              kana: data.SHIIRESAKI_KANA_NAME,
              toshinSection: data.TOSHIN_KEIYU_KBN,
              panasonicSection: data.PANASONIC_KBN,
              commissionSection: data.TESURYO_KBN,
              supplierRebateSection: data.SHIIRESAKI_REBATE_KBN,
              purchaseOrderClass: data.HACCHUSHO_KBN,
            });
          }
        } else {
          // データを取得できなかった場合
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query: "listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        console.log(JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }

      // 担当者用コンボボックスのリスト生成
      condition = {
        where_options:
          "AND BUMON_CD = " +
          this.loginUserInfo.BUMON_CD +
          " ORDER BY TANTOSHA_CD",
      };
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listM_TANTOSHA, condition)
        );
        // 結果判定
        if (result.data.listM_TANTOSHA) {
          // データを取得できた場合
          // 取得結果をリストに追加
          let resultData = result.data.listM_TANTOSHA;
          for (const data of resultData) {
            this.tantoshaList.push({
              comboItem: data.TANTOSHA_CD + "：" + data.TANTOSHA_NAME,
              code: data.TANTOSHA_CD,
              name: data.TANTOSHA_NAME,
              userCode: data.LOGIN_USER_CD,
            });
          }
        } else {
          // データを取得できなかった場合
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query: "listM_TANTOSHA", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        console.log(JSON.stringify(error));
        // // ログ出力(exception)
        // addLog('ERROR', {message: MSG.ERROR["3006"], query: "listM_TANTOSHA", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }

      // 検索画面からの遷移の場合、更新値を取得
      this.reqMitsumoriDataId = this.$route.query.reqMitsumoriDataId
      // const reqMitsumoriDataId = this.reqMitsumoriDataId
      // console.log({reqMitsumoriDataId});
      if (this.reqMitsumoriDataId) {
        // 既存データ修正の場合
        // 検索画面で指定された発注データ、発注明細データの取得
        let result = await this.setInitMitsumoriData(this.reqMitsumoriDataId);
        if (!result) {
          return false;
        }
        // 登録時の担当者を表示
        let insertManager = this.tantoshaList.find(
            (v) => v.userCode == this.headerData.loginUserCd
        );
        if (!insertManager) {
          this.tantoshaList.push({
            comboItem: this.headerData.tantoshaCd + "：" + this.headerData.tantoshaName,
            code: this.headerData.tantoshaCd,
            name: this.headerData.tantoshaName,
            userCode: this.headerData.loginUserCd,
          });
        } else {
          this.headerData.loginUserCd = insertManager.userCode;
          this.headerData.tantoshaCd = insertManager.code;
          this.headerData.tantoshaName = insertManager.name;
        }
      } else {
        // 新規登録の場合
        // ログイン中のユーザーで表示
        let initManager = this.tantoshaList.find(
          (v) => v.userCode == this.loginUserInfo.LOGIN_USER_CD
        );
        this.headerData.loginUserCd = initManager.userCode;
        this.headerData.tantoshaCd = initManager.code;
        this.headerData.tantoshaName = initManager.name;

        // 作成日付・見積日付に当日を入力
        const today = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        ).toISOString().substr(0, 10);
        this.headerData.sakuseiDate = today;
        this.headerData.mitsumoriDate = today;

        // 初期表示で行を15行追加
        this.addLine(15);
      }
      // 入力前のデータを保存
      this.saveOriginalParams();

      return true;
    },
    /**
     * 得意先カナのコンボボックス更新時処理
     */
    updateTokuisakiCombo() {
      if (this.headerData.tokuisakiData !== null) {
        // const list = this.tokuisakiList;
        // console.log({list});
        // const kanaName = this.headerData.tokuisakiData;
        // console.log({kanaName});
        // 仕入先名、パナソニック区分、印刷フォーマットを設定
        let selectedTokuisaki = this.tokuisakiList.find(
          (v) => v.kana == this.headerData.tokuisakiData.kana
        );
        // console.log({selectedTokuisaki});
        if(selectedTokuisaki) {
          this.headerData.tokuisakiName = selectedTokuisaki.name;
          this.headerData.tokuisakiCd = selectedTokuisaki.code;
        }
      } else {
        this.headerData.tokuisakiName = null;
        this.headerData.tokuisakiCd = null;
      }
    },
    /**
     * 担当者コードのコンボボックス更新時処理
     */
    updateTantoshaCombo() {
      if (this.headerData.tantoshaCd) {
        // 担当者名を設定
        // const tantoshaCd = this.headerData.tantoshaCd;
        // console.log({tantoshaCd});
        // const tantoshaList = this.tantoshaList;
        // console.log({tantoshaList});
        let selectedTantosha = this.tantoshaList.find(
          (v) => v.code == this.headerData.tantoshaCd
        );
        this.headerData.loginUserCd = selectedTantosha.userCode;
        this.headerData.tantoshaName = selectedTantosha.name;
        this.headerData.tantoshaLoginUserCode = selectedTantosha.userCode;
      } else {
        this.headerData.loginUserCd = null;
        this.headerData.tantoshaName = null;
        this.headerData.tantoshaLoginUserCode = null;
      }
    },
    /**
     * 未保存チェック用比較データの更新(見積ヘッダ、見積明細)
     */
    saveOriginalParams() {
      // 見積ヘッダ
      this.originalHeader = JSON.parse(
        JSON.stringify(this.headerData)
      );
      // 見積明細
      this.originalDetails = JSON.parse(
        JSON.stringify(this.mitsumoriMeisaiList)
      );
    },
    /**
     * 未保存チェック(見積ヘッダ、見積明細)
     */
    noDataChanges() {
      return (
        this.headerChanged() &&
        this.detailsChanged()
      );
    },
    /**
     * 未保存チェック(見積ヘッダ)
     */
    headerChanged() {
      return (
        JSON.stringify(this.headerData) ==
        JSON.stringify(this.originalHeader)
      );
    },
    /**
     * 未保存チェック(見積明細)
     */
    detailsChanged() {
      let listLength = this.mitsumoriMeisaiList.length;
      if (this.originalDetails.length > this.mitsumoriMeisaiList.length) {
        listLength = this.originalDetails.length;
      }
      for (var i = 0; i <= listLength; i++) {
        if (
          JSON.stringify(this.originalDetails[i]) !==
          JSON.stringify(this.mitsumoriMeisaiList[i])
        ) {
          return false;
        }
      }
      return true;
    },
    // 見積初期データ取得処理
    async setInitMitsumoriData(mitsumoriDataId) {
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        const rowData = await this.getMitsumoriData(mitsumoriDataId);

        if(rowData.length > 0) {
          // 見出しデータのセット
          this.setMitsumoriHeader(rowData[0]);
          // データのフォーマット
          const itemData = this.formatRows(rowData);
          // 商品テーブルの仕入れ単価を取得してセット
          // console.log({itemData});
          const itemDataWithShiireTanka = await this.setShiireTanka(itemData);
          // テーブルデータセット
          // console.log(`テーブルデータセット`);
          this.mitsumoriMeisaiList = itemDataWithShiireTanka;
          // 合計金額・利益の計算
          this.setPrices(itemData);
          return true;
        } else {
          // TODO データを取得できなかった際の処理
          return false;
        }
      } catch (error) {
        console.error(error);
        // ログ出力
        addLog('ERROR', {
          errorType: MSG.ERROR["002"],
          message: MSG.ERROR["002"],
          errorMessage: error.message,
        })
        return false;
      }
    },
    /**
     * バリデーションチェックをリセット
     */
    resetValidate() {
      // ヘッダーのバリデーションをリセット
      this.$refs.formHeader.resetValidation();
      // 明細行のバリデーションをリセット
      if (this.$refs.formDetails) {
        this.$refs.formDetails.resetValidation();
      }
      // モーダルのバリデーションをリセット
      if (this.$refs.formModal) {
        this.$refs.formModal.resetValidation();
      }
    },
    /**
     * バリデーションチェック
     */
    validateCheck() {
      // 発注データ、発注明細データのバリデーション
      return this.validateCheckHeader() && this.validateCheckDetails();
    },
    /**
     * バリデーションチェック(見積ヘッダ)
     */
    validateCheckHeader() {
      // 発注データのバリデーション
      let form = this.$refs.formHeader;
      if (!form.validate()) {
        // 最初のエラー箇所へスクロール
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return false;
      }
      return true;
    },
    /**
     * バリデーションチェック(行追加時の発注明細データ)
     */
    validateCheckModal() {
      // 発注明細データのバリデーション
      let form = this.$refs.formModal;
      if (!form.validate()) {
        // 最初のエラー箇所へスクロール
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return false;
      }
      return true;
    },
    /**
     * バリデーションチェック(明細行の発注明細データ)
     */
    validateCheckDetails() {
      // メソッド名を定義
      const method_name="validateCheckDetails"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 発注明細データのバリデーション
      let form = this.$refs.formDetails;
      let returnFlg = true;
      if (!form.validate()) {
        // エラー情報を取得
        let result = form.inputs.find((input) => input.hasError);
        console.dir(result);
        for (const header of this.headers) {
          if (header.value == result.id) {
            // ユーザーの操作がない（初期値と入力後で値が変わらない）場合はcontinue
            // ただしオートコンプリート（result.itemTextがcomboItem）の場合はこのチェックは行わない
            if (result.initialValue === null && result.lazyValue === null && result.itemText !== 'comboItem') {
              continue;
            }
            alert(header.text + "の値が不正です。\n" + result.value);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            returnFlg = false;
          }
        }
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return returnFlg;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return returnFlg;
    },
    /**
     * 不要な行の削除処理
     */
    deleteTable() {
      // 空欄行以外を検索する
      let notNUllList = [];
      for (const data of this.mitsumoriMeisaiList) {
        // 明細行とdefaultItemが一致していれば行削除
        for (const key in this.defaultItem) {
          if (key == "meisaiNo") {
            continue;
          }
          // 入力値があれば明細Noを取得
          if (this.defaultItem[key] !== data[key]) {
            notNUllList.push(data.meisaiNo);
            continue;
          }
        }
      }
      // 明細Noの重複を削除
      notNUllList = [...new Set(notNUllList)];
      // データテーブル行数とnotNUllListが一致していれば処理を抜ける
      if (this.mitsumoriMeisaiList.length !== notNUllList.length) {
        // 空欄行を削除する
        let result = this.mitsumoriMeisaiList.filter((a) => {
          if (notNUllList.indexOf(a.meisaiNo) !== -1) {
            return a;
          }
        });
        // 明細No順にソートする
        result.sort((a, b) => a.meisaiNo - b.meisaiNo);
        // 明細Noの振り直しを行う
        for (var i = 0; i < result.length; i++) {
          // 仕入先コードが空の場合、処理を抜ける
          if (!result[i].shiiresakiCd) {
            alert(
              "明細No." + result[i].meisaiNo + "の仕入先コードが空欄です"
            );
            return false;
          }
          // 商品コードが空の場合、処理を抜ける
          if (!result[i].shohinCd) {
            alert(
              "明細No." + result[i].meisaiNo + "の商品コードが空欄です"
            );
            return false;
          }
          result[i].meisaiNo = i + 1;
        }
        this.mitsumoriMeisaiList = result;
      }
      return true;
    },
    /**
     * 登録ボタン押下処理
     */
    async onClickRegister() {
      // console.log("onClickRegister [in]");
      // バリデーションチェックでエラーの際は登録しない
      if (!(await this.validateCheck())) {
        // console.log('validation error');
        return;
      }
      // 保存済みデータの場合、ダイアログを表示
      if (this.noDataChanges()) {
        alert(ALERT_ALREADY_SAVED_DATA);
        return;
      }
      if (!confirm(CONFIRM_MESSAGE_SAVE_DATA)) {
        return;
      }
      // 空行を削除と商品コードのnullチェックを行う
      if (!(await this.deleteTable())) {
        return;
      }
      if (this.mitsumoriMeisaiList.length === 0) {
        alert(ALERT_SAVE_ZERO_DATA);
        // 行を15行追加
        this.addLine(15);
        return;
      }

      // ローディング画面の表示
      this.$store.commit("setLoading", true);

      // 修正ボタン押下もしくは連続登録しなかった場合
      if (typeof this.headerData.mitsumoriDataId == "number") {
        // 修正：入力データで更新
        if (!(await this.updateTable())) {
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // エラーダイアログを表示
          alert(ALERT_MESSAGE_COMMON_ERROR);
          return;
        }
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // 完了ダイアログを表示
        alert(ALERT_COMPLETE_CRUD_DATA);
      } else {
        // 新規登録：入力データを登録
        if (!(await this.insertTable())) {
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // エラーダイアログを表示
          alert(ALERT_MESSAGE_COMMON_ERROR);
          return;
        }
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // 完了ダイアログを表示
        alert(ALERT_COMPLETE_CRUD_DATA);

        if (confirm(CONFIRM_MESSAGE_CLEAR_DATA)) {
          // 連続登録の場合

          // ローディング画面の表示
          this.$store.commit("setLoading", true);
          // 画面上のデータを初期化
          // 作成日付・見積日付に当日を入力
          const today = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          ).toISOString().substr(0, 10);
          // ログイン中のユーザーで表示
          let insertManager = this.tantoshaList.find(
              (v) => v.userCode == this.loginUserInfo.LOGIN_USER_CD
          );
          this.headerData.mitsumoriDataId = null;
          this.headerData.mitsumoriNo = null;
          this.headerData.mitsumoriDate = today;
          this.headerData.sakuseiDate = today;
          this.headerData.kenmeiName = null;
          this.headerData.yukoKigenSu = 30;
          this.headerData.nokiSu = 30;
          this.headerData.gokeiKingakuGk = null;
          this.headerData.tokuisakiData = null;
          this.headerData.tokuisakiCd = null;
          this.headerData.tokuisakiName = null;
          this.headerData.tekiyo = null;
          this.headerData.loginUserCd = insertManager ? insertManager.userCode : null;
          this.headerData.tantoshaCd = insertManager ? insertManager.code : null;
          this.headerData.tantoshaName = insertManager ? insertManager.name : null;
          this.headerData.createdDate = null;
          this.headerData.createdUser = null;
          this.headerData.updatedDate = null;
          this.headerData.updatedUser = null;

          this.mitsumoriMeisaiList = [];
          // 行を15行追加
          this.addLine(15);
          // 未保存チェック用配列を更新
          this.saveOriginalParams();

          // バリデーションエラーをリセット
          this.resetValidate();
        }
      }
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // console.log("onClickRegister [out]");
    },
    /**
     * 新規登録処理
     */
    async insertTable() {
      // メソッド名を定義
      const method_name="insertTable"

      // console.log("insertTable [in]");
      // 発注データテーブルへの登録
      // コード最大値の取得
      let registeringMitsumoriNo;
      let sqlCounterTables;
      // 検索条件 パナソニック区分またはその他で分ける
      let condition = {
        BUMON_CD: this.loginUserInfo.BUMON_CD,
      };
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(getT_CHOHYO_COUNTER, condition)
        );
        // console.log(
        //   "getT_CHOHYO_COUNTER：result = " +
        //     { result } +
        //     ", condition = " +
        //     JSON.stringify(condition)
        // );

        // 結果判定
        if (result.data.getT_CHOHYO_COUNTER) {
          // データを取得できた場合
          // DBから取得した値を設定
          let resultData = result.data.getT_CHOHYO_COUNTER;
          registeringMitsumoriNo = resultData.MITSUMORISHO_NO + 1;
          sqlCounterTables = `MITSUMORISHO_NO = ${registeringMitsumoriNo},`;
          // console.log({registeringMitsumoriNo});
          // console.log({sqlCounterTables});
        } else {
          if (result.errors) {
            // データを取得できなかった場合
            // ログ出力(SQL実行でエラー)
            // console.log(
            //   "getT_CHOHYO_COUNTER(error1)：result = " +
            //     JSON.stringify(result)
            // );
            addLog('ERROR', {message: MSG.ERROR["3010"], query: "getT_CHOHYO_COUNTER", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // データ0件時
            return false;
          }
        }
      } catch (error) {
        // Exception発生の場合
        // 業務ログ出力
        // console.log(
        //   "getT_CHOHYO_COUNTER(error2)： error = " + { error }
        // );
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "getT_CHOHYO_COUNTER", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }

      // 件名
      let kenmeiString = !this.headerData.kenmeiName ? null : `'${this.headerData.kenmeiName}'`;
      // 摘要
      let tekiyoString = !this.headerData.tekiyo ? null : `'${this.headerData.tekiyo}'`;

      let sqlList = [];
      // 見積データ用SQLを追加
      sqlList.push(
        `INSERT INTO T_MITSUMORI_DATA ` +
        `(BUMON_CD, MITSUMORI_NO, MITSUMORI_DATE, SAKUSEI_DATE, KENMEI_NAME, YUKOKIGEN_SU, ` +
        `NOKI_SU, CSS_TOKUISAKI_CD, CSS_TOKUISAKI_NAME, ` +
        `TEKIYO, LOGIN_USER_CD, TANTOSHA_CD, TANTOSHA_NAME, ` +
        `CREATE_DATE, CREATE_USER_NAME, UPDATE_DATE, UPDATE_USER_NAME) VALUES (` +
          `${this.loginUserInfo.BUMON_CD},` + //BUMON_CD
          `${registeringMitsumoriNo},` + //MITSUMORI_NO
          `'${this.headerData.mitsumoriDate}',` + //MITSUMORI_DATE
          `'${this.headerData.sakuseiDate}',` + //SAKUSEI_DATE
          `${kenmeiString},` + //KENMEI_NAME
          `${this.headerData.yukoKigenSu},` + //YUKOKIGEN_SU
          `${this.headerData.nokiSu},` + //NOKI_SU
          `${this.headerData.tokuisakiCd},` + //CSS_TOKUISAKI_CD
          `'${this.headerData.tokuisakiName}',` + //CSS_TOKUISAKI_NAME
          `${tekiyoString},` + //TEKIYO
          `'${this.headerData.loginUserCd}',` + //LOGIN_USER_CD
          `${this.headerData.tantoshaCd},` + //TANTOSHA_CD
          `'${this.headerData.tantoshaName}',` + //TANTOSHA_NAME
          "CURDATE()," + //CREATE_DATE
          `'${this.loginUserInfo.LOGIN_USER_CD}',` + //CREATE_USER_NAME
          "CURDATE()," + //UPDATE_DATE
          `'${this.loginUserInfo.LOGIN_USER_CD}');` //UPDATE_USER_NAME
      );

      // 見積明細データ用SQLを追加
      let insertMeisaiSql = "";
      insertMeisaiSql += "INSERT INTO T_MITSUMORI_MEISAI_DATA VALUES ";
      // 備考
      let biko = null;
      // コメント
      let comment = null;
      // 仕入先名
      let shiiresakiName = null;
      // 型番
      let katabanNo = null;
      // 品名
      let shohinName = null;
      for (let i = 0; i < this.mitsumoriMeisaiList.length; i++) {
        const tableData = this.mitsumoriMeisaiList[i];
        biko = !tableData.biko ? null : `'${tableData.biko}'`;
        comment = !tableData.comment ? null : `'${tableData.comment}'`;
        shiiresakiName = !tableData.shiiresakiName ? null : `'${tableData.shiiresakiName}'`;
        katabanNo = !tableData.katabanNo ? null : `'${tableData.katabanNo}'`;
        shohinName = !tableData.shohinName ? null : `'${tableData.shohinName}'`;
        insertMeisaiSql += `(`;
        insertMeisaiSql += `LAST_INSERT_ID(),`; //MITSUMORI_DATA_ID
        insertMeisaiSql += `${tableData.meisaiNo},`; //MEISAI_NO
        insertMeisaiSql += `${tableData.shiiresakiCd},`; //SHIIRESAKI_CD
        insertMeisaiSql += `${tableData.shohinCd},`; //SHOHIN_CD
        insertMeisaiSql += `${katabanNo},`; //KATABAN_NO
        insertMeisaiSql += `${shiiresakiName},`; //SHIIRESAKI_NAME
        insertMeisaiSql += `${shohinName},`; //SHOHIN_NAME
        insertMeisaiSql += `${tableData.genkaGk},`; //GENKA_GK
        insertMeisaiSql += `${tableData.tankaGk},`; //TANKA_GK
        insertMeisaiSql += `${tableData.tekaGk},`; //TEKA_GK
        insertMeisaiSql += `${tableData.suryoSu},`; //SURYO_SU
        insertMeisaiSql += `${tableData.kingakuGk},`; //KINGAKU_GK
        insertMeisaiSql += `${tableData.toshinNetGk},`; //TOSHIN_NET_GK
        insertMeisaiSql += `${biko},`; //BIKO
        insertMeisaiSql += `${comment},`; //COMMNET
        insertMeisaiSql += `CURDATE(),`; //CREATE_DATE
        insertMeisaiSql += `'${this.loginUserInfo.LOGIN_USER_CD}',`; //CREATE_USER_NAME
        insertMeisaiSql += `CURDATE(),`; //UPDATE_DATE
        insertMeisaiSql += `'${this.loginUserInfo.LOGIN_USER_CD}'`; //UPDATE_USER_NAME
        insertMeisaiSql += `)`; //UPDATE_USER_NAME
        if (i !== this.mitsumoriMeisaiList.length - 1) {
          insertMeisaiSql += `,`;
        }
      }
      insertMeisaiSql += `;`;
      sqlList.push(insertMeisaiSql);

      // カウンタテーブルの値を更新
      sqlList.push(
        `UPDATE T_CHOHYO_COUNTER SET ` +
          sqlCounterTables +
          ` UPDATE_DATE = CURDATE(),` +
          ` UPDATE_USER_NAME = '${this.loginUserInfo.LOGIN_USER_CD}'`+
          ` WHERE BUMON_CD = '${this.loginUserInfo.BUMON_CD}';`
      );

      condition = { SQLs: sqlList };
      // console.log("insertTable : condition = " + JSON.stringify(condition));

      let registeredDataId = -1;
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeTransactSql, condition)
        );

        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(result.data.executeTransactSql.body);

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeTransactSql.statusCode > 200) {
            // // エラー内容
            // const errorMessage = responseBody.error;
            // console.error({ errorMessage });
            // console.info("insertTable：エラー" + responseBody);
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], {message: responseBody?.error});
            return false;
          } else {
            // SQLが正常に終了した際の処理
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
              // console.log(
              //   "responseBody.data[0][0] = " +
              //     JSON.stringify(responseBody.data[0][0])
              // );
              registeredDataId = responseBody.data[0][0]["LAST_INSERT_ID()"];
              // console.log("registeredDataId = " + registeredDataId);
            }
          }
        } else {
          // レスポンスデータを取得できなかった際の処理
          // console.lg({result});
          // console.log("insertTable：エラー2 : " + JSON.stringify(result));
          // ログ出力(Lamda関数でのSQL実行でステータスエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // console.log("insertTable : 異常終了 = " + { error });
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }

      // 新規登録した発注データ・発注明細データの取得
      let success = await this.setInitMitsumoriData(registeredDataId);
      if (!success) {
        return false;
      }
      // 未保存チェック用配列を更新
      this.saveOriginalParams();

      // バリデーションエラーをリセット
      this.resetValidate();
      return true;
    },
    /**
     * 修正処理
     */
    async updateTable() {
      // メソッド名を定義
      const method_name="updateTable"

      // console.log("updateTable [in]");

      // const userInfo = this.loginUserInfo;
      // console.log({userInfo});

      // 件名
      let kenmeiString = !this.headerData.kenmeiName ? null : `'${this.headerData.kenmeiName}'`;
      // 摘要
      let tekiyoString = !this.headerData.tekiyo ? null : `'${this.headerData.tekiyo}'`;

      let sqlList = [];
      // 発注データ用SQLを追加
      sqlList.push(
        `UPDATE T_MITSUMORI_DATA SET` +
          ` BUMON_CD = ${this.headerData.bumonCd},` +
          ` MITSUMORI_NO = '${this.headerData.mitsumoriNo}',` +
          ` MITSUMORI_DATE = '${this.headerData.mitsumoriDate}',` +
          ` SAKUSEI_DATE = '${this.headerData.sakuseiDate}',` +
          ` KENMEI_NAME = ${kenmeiString},` +
          ` YUKOKIGEN_SU = ${this.headerData.yukoKigenSu},` +
          ` NOKI_SU = ${this.headerData.nokiSu},` +
          ` CSS_TOKUISAKI_CD = ${this.headerData.tokuisakiCd},` +
          ` CSS_TOKUISAKI_Name = '${this.headerData.tokuisakiName}',` +
          ` TEKIYO = ${tekiyoString},` +
          ` LOGIN_USER_CD = '${this.loginUserInfo.loginUserCode}',` +
          ` TANTOSHA_CD = ${this.headerData.tantoshaCd},` +
          ` TANTOSHA_NAME = '${this.headerData.tantoshaName}',` +
          ` UPDATE_DATE = CURDATE(),` +
          ` UPDATE_USER_NAME = '${this.loginUserInfo.LOGIN_USER_CD}'` +
          ` WHERE MITSUMORI_DATA_ID = ${this.headerData.mitsumoriDataId};`
      );

      // 見積明細データを全消し
      sqlList.push(
        `DELETE FROM T_MITSUMORI_MEISAI_DATA` +
          ` WHERE MITSUMORI_DATA_ID = ${this.headerData.mitsumoriDataId};`
      );
      // 備考
      let biko = null;
      // コメント
      let comment = null;
      // 仕入先名
      let shiiresakiName = null;
      // 型番
      let katabanNo = null;
      // 品名
      let shohinName = null;
      // 見積明細データ用SQLを追加
      let insertMeisaiSql = "";
      insertMeisaiSql += "INSERT INTO T_MITSUMORI_MEISAI_DATA VALUES ";

      for (let i = 0; i < this.mitsumoriMeisaiList.length; i++) {
        const tableData = this.mitsumoriMeisaiList[i];
        // 件名
        biko = !tableData.biko ? null : `'${tableData.biko}'`;
        comment = !tableData.comment ? null : `'${tableData.comment}'`;
        shiiresakiName = !tableData.shiiresakiName ? null : `'${tableData.shiiresakiName}'`;
        katabanNo = !tableData.katabanNo ? null : `'${tableData.katabanNo}'`;
        shohinName = !tableData.shohinName ? null : `'${tableData.shohinName}'`;
        insertMeisaiSql += `(`;
        insertMeisaiSql += `${this.headerData.mitsumoriDataId},`; //MITSUMORI_DATA_ID
        insertMeisaiSql += `${tableData.meisaiNo},`; //MEISAI_NO
        insertMeisaiSql += `${tableData.shiiresakiCd},`; //SHIIRESAKI_CD
        insertMeisaiSql += `${tableData.shohinCd},`; //SHOHIN_CD
        insertMeisaiSql += `${katabanNo},`; //KATABAN_NO
        insertMeisaiSql += `${shiiresakiName},`; //SHIIRESAKI_NAME
        insertMeisaiSql += `${shohinName},`; //SHOHIN_NAME
        insertMeisaiSql += `${tableData.genkaGk},`; //GENKA_GK
        insertMeisaiSql += `${tableData.tankaGk},`; //TANKA_GK
        insertMeisaiSql += `${tableData.tekaGk},`; //TEKA_GK
        insertMeisaiSql += `${tableData.suryoSu},`; //SURYO_SU
        insertMeisaiSql += `${tableData.kingakuGk},`; //KINGAKU_GK
        insertMeisaiSql += `${tableData.toshinNetGk},`; //TOSHIN_NET_GK
        insertMeisaiSql += `${biko},`; //BIKO
        insertMeisaiSql += `${comment},`; //COMMNET
        insertMeisaiSql += `CURDATE(),`; //CREATE_DATE
        insertMeisaiSql += `'${this.loginUserInfo.LOGIN_USER_CD}',`; //CREATE_USER_NAME
        insertMeisaiSql += `CURDATE(),`; //UPDATE_DATE
        insertMeisaiSql += `'${this.loginUserInfo.LOGIN_USER_CD}'`; //UPDATE_USER_NAME
        insertMeisaiSql += `)`; //UPDATE_USER_NAME
        if (i !== this.mitsumoriMeisaiList.length - 1) {
          insertMeisaiSql += `,`;
        }
      }
      insertMeisaiSql += `;`;
      sqlList.push(insertMeisaiSql);

      let condition = { SQLs: sqlList };
      // console.log("updateTables：condition = " + JSON.stringify(condition));
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeTransactSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(result.data.executeTransactSql.body);

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeTransactSql.statusCode > 200) {
            // console.info({responseBody});
            // // レスポンスメッセージ
            // let message = responseBody.message;
            // console.error({message});
            // // エラー内容
            // const errorMessage = responseBody.error;
            // console.error({errorMessage});
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // SQLが正常に終了した際の処理
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
              for (const rows of responseBody.data) {
                console.dir(rows, { depth: null });
              }
            }
          }
          console.info(JSON.parse(result.data.executeTransactSql.body));
        } else {
          // レスポンスデータを取得できなかった際の処理
          // console.log("updateTables : " + JSON.stringify(result));
          // ログ出力(Lamda関数でのSQL実行でステータスエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // console.log("updateTables : 異常終了 = " + JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }

      // console.log('データ更新完了');

      // 更新した見積ヘッダ・見積明細データの取得
      let result = await this.setInitMitsumoriData(
        this.headerData.mitsumoriDataId
      );
      if (!result) {
        return false;
      }
      // 未保存チェック用配列を更新
      this.saveOriginalParams();

      // バリデーションエラーをリセット
      this.resetValidate();
      return true;
    },
    // APIを実行し、見積データを取得
    async getMitsumoriData(mitsumoriDataId) {
      // 明細データ取得処理
      // WHERE句生成
      let whereOptions = '';

      // 見積データID
      whereOptions += `AND MITSUMORI_DATA_ID = ${mitsumoriDataId} ORDER BY MEISAI_NO ASC`;

      let condition = { where_options: whereOptions };
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(graphqlOperation(listV_MITSUMORI_DATA_WITH_MEISAI, condition));
        
        let resultData = [];
        if(result.data.listV_MITSUMORI_DATA_WITH_MEISAI) {
          // データを取得できた際の処理
          resultData = result.data.listV_MITSUMORI_DATA_WITH_MEISAI;
          // console.info(resultData);
        } 
        return resultData;
      } catch (error) {
        console.error(error);
        // ログ出力
        addLog('ERROR', {
          errorType: MSG.ERROR["002"],
          message: MSG.ERROR["002"],
          errorMessage: error.message,
        })
        throw error;
      }
    },
    // 見出しデータをセット
    setMitsumoriHeader(row) {
      // console.log({row});
      // 見積データID
      this.headerData.mitsumoriDataId = row.MITSUMORI_DATA_ID;
      // 部門コード
      this.headerData.bumonCd = row.BUMON_CD;
      // 見積番号
      this.headerData.mitsumoriNo = row.MITSUMORI_NO;
      // 見積日付
      this.headerData.mitsumoriDate = row.MITSUMORI_DATE;
      // 作成日付
      this.headerData.sakuseiDate = row.SAKUSEI_DATE;
      // 件名
      this.headerData.kenmeiName = row.KENMEI_NAME ? row.KENMEI_NAME : '';
      // 有効期限
      this.headerData.yukoKigenSu = row.YUKOKIGEN_SU;
      // 納期
      this.headerData.nokiSu = row.NOKI_SU;
      // 得意先コード
      this.headerData.tokuisakiCd = row.CSS_TOKUISAKI_CD;
      // 得意先名
      this.headerData.tokuisakiName = row.CSS_TOKUISAKI_NAME;
      // 摘要
      this.headerData.tekiyo = row.TEKIYO;
      // 担当者ログインユーザーコード
      this.headerData.loginUserCd = row.LOGIN_USER_CD;
      // 担当者コード
      this.headerData.tantoshaCd = row.TANTOSHA_CD;
      // 担当者名
      this.headerData.tantoshaName = row.TANTOSHA_NAME;



      // 得意先コンボボックスデータ
      this.headerData.tokuisakiData = this.tokuisakiList.find(
          (v) => v.code == row.CSS_TOKUISAKI_CD
        );
    },
    // APIで取得したデータをフォーマット
    formatRows(rows) {
      // console.log({rows});
      let items = [];
      for (const row of rows) {
        // console.log({row});
        const item = {
          mitsumoriDataId: row.MITSUMORI_DATA_ID,
          meisaiNo: row.MEISAI_NO,
          shiiresakiCd: row.SHIIRESAKI_CD,
          shohinCd: row.SHOHIN_CD,
          katabanNo: row.KATABAN_NO,
          shiiresakiName: row.SHIIRESAKI_NAME,
          shiiresakiKanaName: row.SHIIRESAKI_KANA_NAME,
          shohinName: row.SHOHIN_NAME,
          suryoSu: row.SURYO_SU,
          tankaGk: row.TANKA_GK,
          kingakuGk: row.KINGAKU_GK,
          tekaGk: row.TEKA_GK,
          genkaGk: row.GENKA_GK,
          genkaKingakuGk: row.SURYO_SU * row.GENKA_GK,
          toshinNetGk: row.TOSHIN_NET_GK,
          biko: row.BIKO == "null" ? '' : row.BIKO,
          comment: row.COMMENT == "null" ? '' : row.COMMENT,
        }
        items.push(item);
      }
      return items;
    },
    // 登録済みデータ編集の場合、商品テーブルの仕入れ単価を取得する
    async setShiireTanka(itemData) {
      // メソッド名を定義
      const method_name="setShiireTanka"

      // 商品コードを抜き出し
      const originalShohinCdList = itemData.map(function(item) {
        return item.shohinCd;
      });
      // 重複を削除してIN句の文字列を作成
      const shohinCdList = Array.from(new Set(originalShohinCdList))
      const shohinCds = shohinCdList.join(',');

      // 商品コードに紐づくデータを取得
      let resultSerchData;
      let sqlSerchList = [
        `SELECT SH_SHOCD, SH_SHOKNM, SH_SHONM, SH_SIRTNA, SH_JYOTNA, SH_JAN FROM SHOHIN  WHERE SH_SHOCD IN (${shohinCds})`
      ];
      // console.log('sqlSerchList[0]');
      // console.log(sqlSerchList[0]);
      // 商品コードに紐づくデータを取得
      try {
        // AppSync→販売管理検証DB(Oracle)でデータ取得
        let result = await executeHanbaikanriSqls(this.$route.name, method_name, sqlSerchList);
        if (!result.body.error) {
          resultSerchData = result.body.data[0];
          // console.log({resultSerchData});
        } else {
          // レスポンスデータを取得できなかった際の処理
          // エラーダイアログを表示
          alert("販売管理DBから商品データを取得できませんでした。\nしばらくしてから、もう一度お試しください。");
          return false;
        }
      } catch (error) {
        console.error("error" + JSON.stringify(error));
        // ログ出力(exception)
        alert("販売管理DBからの商品データ取得時にエラーが発生しました。\nしばらくしてから、もう一度お試しください。");
        this.$store.commit("setLoading", false);
        return false;
      }
      // 仕入れ単価セット
      for (const item  of itemData) {
        let data = resultSerchData.find(
          (items) => items.SH_SHOCD == item.shohinCd
        );
        if (data !== void 0) {
          item.shohinGenka = data.SH_SIRTNA;
        }
      }
      // console.log({itemData});
      // console.log('setShiireTanka end');
      return itemData;
    },
    // 保存ボタン
    submit() {
      const form = this.$refs.form;
      if (form.validate()) {
        // すべてのバリデーションが通過したときのみif文の中に入る
        this.success = true;
        // console.log("バリデーションOK");
        alert(
          "保存処理が実行され、完了メッセージが表示される。見積番号の採番等が行われる。画面内容が更新される。"
        );
      } else {
        this.success = false;
        // console.log("バリデーションNG");

        // 最初のエラー箇所へスクロール
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return;
      }
    },
    // 見積書PDF出力ボタン
    async printing() {
      // 新規作成時は保存必須
      if (!this.headerData.mitsumoriDataId) {
        alert(ALERT_UNSAVED_DATA);
        return;
      }
      // 未保存チェックを実施し、未保存の場合はエラーを表示
      if (!this.noDataChanges()) {
        if (confirm(CONFIRM_MESSAGE_UNSAVED_DATA)) {
          // バリデーションチェックでエラーの際は登録しない
          if (!(await this.validateCheck())) {
            return;
          }
          // ローディング画面の開始
          this.$store.commit("setLoading", true);
          // 空行を削除と商品コードのnullチェックを行う
          if (!(await this.deleteTable())) {
            return;
          }
          if (this.mitsumoriMeisaiList.length === 0) {
            alert(ALERT_SAVE_ZERO_DATA);
            // 行を15行追加
            this.addLine(15);
            return;
          }
          // 修正時の判定
          if (
            this.reqMitsumoriDataId ||
            typeof this.headerData.mitsumoriDataId == "number"
          ) {
            // 修正：入力データで更新
            if (!(await this.updateTable())) {
              // ローディングを解除
              this.$store.commit("setLoading", false);
              // エラーダイアログを表示
              alert(ALERT_MESSAGE_COMMON_ERROR);
              // 画面遷移しない
              return;
            }
          } else {
            // 新規登録：入力データを登録
            if (!(await this.insertTable())) {
              // ローディングを解除
              this.$store.commit("setLoading", false);
              // エラーダイアログを表示
              alert(ALERT_MESSAGE_COMMON_ERROR);
              // 画面遷移しない
              return;
            }
          }
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // 完了ダイアログを表示
          alert(ALERT_COMPLETE_CRUD_DATA);
        }
      }
      // 空行を削除と商品コードのnullチェックを行う
      if (!(await this.deleteTable())) {
        return;
      }
      if (this.mitsumoriMeisaiList.length === 0) {
        alert(ALERT_SAVE_ZERO_DATA);
        // 行を15行追加
        this.addLine(15);
        return;
      }
      // 見積書PDF出力処理
      let route = this.$router.resolve({ name: 'Estimate', params: { mitsumoriDataId: this.headerData.mitsumoriDataId }});
      window.open(route.href, '_blank');
    },
    // コピーして新規入力ボタン
    async copy() {
      // 未保存チェックを実施し、未保存の場合はエラーを表示
      if (!this.noDataChanges()) {
        if (confirm(CONFIRM_MESSAGE_UNSAVED_DATA)) {
          // バリデーションチェックでエラーの際は登録しない
          if (!(await this.validateCheck())) {
            return;
          }
          // ローディング画面の開始
          this.$store.commit("setLoading", true);
          // 空行を削除と商品コードのnullチェックを行う
          if (!(await this.deleteTable())) {
            return;
          }
          if (this.mitsumoriMeisaiList.length === 0) {
            alert(ALERT_SAVE_ZERO_DATA);
            // 行を15行追加
            this.addLine(15);
            return;
          }
          // 修正時の判定
          if (
            this.reqMitsumoriDataId ||
            typeof this.headerData.mitsumoriDataId == "number"
          ) {
            // 修正：入力データで更新
            if (!(await this.updateTable())) {
              // ローディングを解除
              this.$store.commit("setLoading", false);
              // エラーダイアログを表示
              alert(ALERT_MESSAGE_COMMON_ERROR);
              // 画面遷移しない
              return;
            }
          } else {
            // 新規登録：入力データを登録
            if (!(await this.insertTable())) {
              // ローディングを解除
              this.$store.commit("setLoading", false);
              // エラーダイアログを表示
              alert(ALERT_MESSAGE_COMMON_ERROR);
              // 画面遷移しない
              return;
            }
          }
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // 完了ダイアログを表示
          alert(ALERT_COMPLETE_CRUD_DATA);
        }
      }
      // 見積データIDと見積番号を空に変更
      this.headerData.mitsumoriDataId = null;
      this.headerData.mitsumoriNo = null;
      // 作成日付・見積日付に当日を入力
      const today = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      ).toISOString().substr(0, 10);
      this.headerData.sakuseiDate = today;
      this.headerData.mitsumoriDate = today;
      // 担当者をログインユーザーで上書き
      // 登録時の担当者を表示
      let tantosha = this.tantoshaList.find(
          (v) => v.userCode == this.loginUserInfo.LOGIN_USER_CD
      );
      this.headerData.loginUserCd = tantosha.userCode;
      this.headerData.tantoshaCd = tantosha.code;
      this.headerData.tantoshaName = tantosha.name;
      // 先頭にスクロール
      this.$vuetify.goTo(0);
      // コピーダイアログを表示
      alert(ALERT_COPY_COMPLETE);
    },
    // 編集ボタン
    editItem(item) {
      // モーダルに表示する明細Noの設定
      this.editmeisaiNo = item.meisaiNo;
      this.editedIndex = this.mitsumoriMeisaiList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // 品番のコンボボックスに初期値を設定（既に入力しているデータには入力データ、新規レコードの場合はNULLが設定される）
      this.openProductNo(item);
      // バリデーションエラーをリセット
      this.$refs.formModal.resetValidation();
      this.dialog = true;
    },

    // 削除ボタン
    deleteItem(item) {
      this.editedIndex = this.mitsumoriMeisaiList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    // 削除ダイアログのOKボタン
    deleteItemConfirm() {
      this.mitsumoriMeisaiList.splice(this.editedIndex, 1);
      this.renumberMeisaiNo(this.editedIndex + 1, "delete");
      this.setPrices()
      this.closeDelete();
    },

    // 明細Noの振り直し
    renumberMeisaiNo(meisaiNo, flg) {
      // メソッド名を定義
      const method_name="renumberMeisaiNo"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      for (const row of this.mitsumoriMeisaiList) {
        switch (flg) {
          case "delete":
            if (Number(row.meisaiNo) >= meisaiNo) {
              row.meisaiNo--;
            }
            break;
          default:
            if (Number(row.meisaiNo) >= meisaiNo) {
              row.meisaiNo++;
            }
        }
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    // 商品検索画面からの返り値の取得
    async getProductList(item) {
      // メソッド名を定義
      const method_name="getProductList"

      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      // 商品コードに紐づくデータを取得
      let resultSerchData;
      let sqlSerchList = [
        `SELECT SH_SHOCD, SH_SHOKNM, SH_SHONM, SH_SIRTNA, SH_JYOTNA, SH_JAN FROM SHOHIN  WHERE SH_SHOCD = ${item.shohinCd}`
      ];
      // 商品コードに紐づくデータを取得
      try {
        // AppSync→販売管理検証DB(Oracle)でデータ取得
        let result = await executeHanbaikanriSqls(this.$route.name, method_name, sqlSerchList);
        if (!result.body.error) {
          resultSerchData = result.body.data[0];
          // console.log({resultSerchData});
        } else {
          // レスポンスデータを取得できなかった際の処理
          // エラーダイアログを表示
          alert("販売管理DBから商品データを取得できませんでした。\nしばらくしてから、もう一度お試しください。");
          return false;
        }
      } catch (error) {
        console.error("error" + JSON.stringify(error));
        // ログ出力(exception)
        alert("販売管理DBからの商品データ取得時にエラーが発生しました。\nしばらくしてから、もう一度お試しください。");
        this.$store.commit("setLoading", false);
        return false;
      }
      // 結果判定
      for (const data of resultSerchData) {
        if (item.meisaiNo !== "") {
          item.katabanNo = data.SH_SHOKNM;
          item.shohinCd = data.SH_SHOCD;
          item.shohinName = data.SH_SHONM;
          item.shohinGenka = data.SH_SIRTNA;
          item.tekaGk = data.SH_JYOTNA;
          item.janCode = data.SH_JAN;
        }
      }
      this.setPrices(item);
      // ローディングを解除
      this.$store.commit("setLoading", false);
    },
    // 追加編集ダイアログのクローズ処理
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    // 削除ダイアログのクローズ処理
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    /**
     * 編集ダイアログのOKボタン
     */
    async saveClose() {
      // メソッド名を定義
      const method_name="saveClose"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // バリデーションチェックでエラーの際は追加しない
      if (!this.validateCheckModal()) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      this.$nextTick(() => {
        // 編集インデックスが-1以上の時、行数をリセットする。
        if (this.editedIndex > -1) {
          Object.assign(
            this.mitsumoriMeisaiList[this.editedIndex],
            this.editedItem
          );
        }
        // 金額計算
        this.setPrices();
      });
      // モーダルを閉じる
      this.close();
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 編集ダイアログのOK（続けて入力）ボタン
     */
    async saveAddLine() {
      // メソッド名を定義
      const method_name="saveAddLine"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // バリデーションチェックでエラーの際は追加しない
      if (!this.validateCheckModal()) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      this.$nextTick(() => {
        // 編集インデックスが-1以上の時、行数をリセットする。
        if (this.editedIndex > -1) {
          Object.assign(
            this.mitsumoriMeisaiList[this.editedIndex],
            this.editedItem
          );
        }
        // 直前の明細データの仕入先情報を保持
        let preShiiresakiKanaName = this.editedItem.shiiresakiKanaName;
        let preShiiresakiCd = this.editedItem.shiiresakiCd;
        let preShiiresakiName = this.editedItem.shiiresakiName;
        // editedItemを初期化
        this.editedItem = Object.assign({}, this.defaultItem);
        // 明細最終行の場合、新規レコードを挿入し、連続入力を行う
        if (!this.mitsumoriMeisaiList[this.editedIndex + 1]) {
          this.editedItem.meisaiNo = this.mitsumoriMeisaiList.length + 1;
          // 仕入先情報は直前のデータを引き継ぐ
          this.editedItem.shiiresakiKanaName = preShiiresakiKanaName;
          this.editedItem.shiiresakiCd = preShiiresakiCd;
          this.editedItem.shiiresakiName = preShiiresakiName;
          this.mitsumoriMeisaiList.push(this.editedItem);
        } else {
          // 明細が最終行でない、かつ明細に仕入先コードが入っていない場合
          if (!this.mitsumoriMeisaiList[this.editedIndex + 1].shiiresakiCd) {
            // 仕入先情報は直前のデータを引き継ぐ
            this.mitsumoriMeisaiList[this.editedIndex + 1].shiiresakiKanaName = preShiiresakiKanaName;
            this.mitsumoriMeisaiList[this.editedIndex + 1].shiiresakiCd = preShiiresakiCd;
            this.mitsumoriMeisaiList[this.editedIndex + 1].shiiresakiName = preShiiresakiName;
          }
        }
        this.editItem(this.mitsumoriMeisaiList[this.editedIndex + 1])

        // 金額計算
        this.setPrices();
      });
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    // データテーブルの編集ダイアログ キャンセルボタン
    cancel() {
      // console.log("Dialog cancel");
    },
    // ダイアログを閉じる
    response(item) {
      // console.log('response');
      // console.log({item});
      let returnList = this.mitsumoriMeisaiList.find(
        (items) =>items.meisaiNo === item.returnNo);
      // どのマスタ画面からの戻り値か判定
      // 行追加より検索した場合
      if (this.dialog) {
        this.editedItem.shohinCd = item.returnProductCode;
        this.getProductList(this.editedItem);
      // 明細データより検索した場合
      } else {
        returnList.shohinCd = item.returnProductCode;
        this.getProductList(returnList);
      }
      this.dialogShohinSearch = false;
      this.targetBranchNo = 0;
    },
    /**
     * 行追加ボタン押下時処理
     */
    addLine(loop) {
      // 行追加を行う。
      // チェックされている場合、選択行の一行上に行追加を行う。
      if (this.selected[0]) {
        // 配列を初期化する。
        this.editedItem = Object.assign({}, this.defaultItem);
        this.renumberMeisaiNo(Number(this.selected[0].meisaiNo));
        this.editedItem.meisaiNo =
          Number(this.selected[0].meisaiNo) - 1;
        this.mitsumoriMeisaiList.push(this.editedItem);
        // 選択を解除する
        this.selected = [];
        this.$nextTick(() => {
          // 明細No順にソートする
        this.mitsumoriMeisaiList.sort((a, b) => a.meisaiNo - b.meisaiNo);
        });
        return;
      }
      // 最終行に行追加を行う。
      for (var j = 0; j < loop; j++) {
        // 配列を初期化する。
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.meisaiNo = this.mitsumoriMeisaiList.length + 1;
        this.mitsumoriMeisaiList.push(this.editedItem);
      }
    },
    /**
     * 型番入力時の初期値設定処理
     */
    async openProductNo(item) {
      // メソッド名を定義
      const method_name="openProductNo"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 型番入力用のコンボボックス表示時に表示する初期値を設定
      this.productObj = item.katabanNo;
      if (this.productObj && typeof this.productObj !== 'object') {
        this.productObj = {
          comboItem: item.shohinCd + "：" + item.katabanNo,
          productNo: item.katabanNo,
        };
      }

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
  },
};
</script>
<style scoped>
/* 1列目スクロール時固定 */
.scroll-lock-estimate-input table th:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: white;
}
.scroll-lock-estimate-input table td:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
}
/* 2列目スクロール時固定 */
.scroll-lock-estimate-input table th:nth-child(2) {
  position: sticky;
  left: 50px; /* 前列までの列幅指定 */
  z-index: 2;
  background-color: white;
}
.scroll-lock-estimate-input table td:nth-child(2) {
  position: sticky;
  left: 50px; /* 前列までの列幅指定 */
  z-index: 1;
  background-color: white;
}
/* 3列目スクロール時固定 */
.scroll-lock-estimate-input table th:nth-child(3) {
  position: sticky;
  left: 130px; /* 前列までの列幅指定 */
  z-index: 2;
  background-color: white;
}
.scroll-lock-estimate-input table td:nth-child(3) {
  position: sticky;
  left: 130px; /* 前列までの列幅指定 */
  z-index: 1;
  background-color: white; 
}
</style>